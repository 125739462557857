@import '../../mixins';

.news {
  & .news-card {
    width: 100%;
    height: 100%;

    box-shadow: none;
  }

  & .swiper {
    @include media(#{rem(749)}) {
      overflow: visible;
    }

    &-slide {
      @include media(#{rem(749)}) {
        width: rem(239);
      }
    }
  }
}
